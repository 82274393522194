import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePackagesList() {
  const packagesListTable = ref([])

  const tableColumns = [
    { text: 'Name (En)', value: 'name_en' },
    { text: 'Name (Ar)', value: 'name_ar' },
    { text: 'Points', value: 'points' },
    { text: 'Price (JOD)', value: 'price' },
    { text: 'Image', value: 'image' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const totalPackagesListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    q:searchQuery.value
  })
  const packagesTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchPackages = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-packages/fetchPackages', {
        q:searchQuery.value,
        options: options.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total, packgesTotal } = response.data.data
        packagesListTable.value = filteredData
        totalPackagesListTable.value = total
        packagesTotalLocal.value = packgesTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, planFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchPackages()
  })

  return {
    packagesListTable,
    planFilter,
    searchQuery,
    tableColumns,
    totalPackagesListTable,
    loading,
    options,
    packagesTotalLocal,
    selectedRows,
    fetchPackages,
  }
}

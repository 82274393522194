<template>
  <div id="packages-list">
    <!-- app drawer -->
    <packages-list-add-new
      v-model="isAddNewPackageSidebarActive"
      @refetch-data="fetchPackages"
    ></packages-list-add-new>

    <!-- packages total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in packagesTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Packages </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

     <v-row class="px-2 ma-0">
        <!--plan filter-->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row> 
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="packages-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewPackageSidebarActive = !isAddNewPackageSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Package</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="packagesListTable"
        :options.sync="options"
        :server-items-length="totalPackagesListTable"
        :loading="loading"
        @refetch-data="fetchPackages"
      >
        <!-- name EN -->
        <template #[`item.name_en`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-package-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name_en }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- name AR -->
        <template #[`item.name_ar`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.name_ar }}</span>
          </div>
        </template>

              <!-- Imahe -->
        <template #[`item.image`]="{ item }">
          <div class="d-flex align-center">
            <v-img
              height="80"
              width="50"
              class="auth-tree"
              :src="item.image"
            ></v-img>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{ name: 'apps-package-view', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>

              <!--<v-list-item
                :to="{ name: 'apps-package-edit', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item> -->

              <!-- <v-list-item
                link
                @click="handleDelete(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiEye,
mdiFileDocumentOutline,
mdiPlus,
mdiSquareEditOutline
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import PackagesStoreModule from '../PackagesStoreModule'
import PackagesListAddNew from './PackagesListAddNew.vue'
import usePackagesList from './usePackagesList'

export default {
  components: {
    PackagesListAddNew,
  },
  setup(props, { emit }) {
    const PACKAGES_APP_STORE_MODULE_NAME = 'app-packages'

    // Register module
    if (!store.hasModule(PACKAGES_APP_STORE_MODULE_NAME)) {
      store.registerModule(PACKAGES_APP_STORE_MODULE_NAME, PackagesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGES_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGES_APP_STORE_MODULE_NAME)
    })
    const {
      packagesListTable,
      tableColumns,
      searchQuery,
      totalPackagesListTable,
      loading,
      options,
      packagesTotalLocal,
      selectedRows,
      planFilter,
      fetchPackages,
    } = usePackagesList()

    const isAddNewPackageSidebarActive = ref(false)

    const planOptions = [
      { title: 'Name (EN)', value: 'name_en' },
      { title: 'Name (AR)', value: 'name_ar' },
    ]

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this package?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-packages/deletePackage', id)
            .then(() => {
              packagesListTable.value = packagesListTable.value.filter(item => item.id !== id)
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            })
            .catch(response => {
              console.error(response)
            })
        }
      })
    }

    return {
      packagesListTable,
      tableColumns,
      searchQuery,
      totalPackagesListTable,
      loading,
      options,
      packagesTotalLocal,
      isAddNewPackageSidebarActive,
      selectedRows,
      planOptions,
      planFilter,
      avatarText,
      fetchPackages,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

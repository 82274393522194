<template>
  <v-navigation-drawer
    :value="isAddNewPackageSidebarActive"
    :permanent="isAddNewPackageSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="'20%'"
    app
    @input="(val) => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Packge</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-item-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemData.name_en"
                :rules="[validators.required]"
                outlined
                dense
                label="Name (En)"
                placeholder="Mansaf"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>

              <v-text-field
                v-model="itemData.name_ar"
                :rules="[validators.required]"
                outlined
                dense
                label="Name (Ar)"
                placeholder="منسف"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="itemData.price"
                :rules="[v => !!v || 'This field is required',v => v >= 0 || 'Price should be greater 0']"
                outlined
                dense
                label="Price in JOD"
                placeholder="5 JOD"
                hide-details="auto"
                class="mb-6"
                type="number"
                @change="calculatePoints($event)"
              ></v-text-field>

              <v-text-field
                v-model="itemData.points"
                outlined
                dense
                disabled
                label="Points"
                placeholder="50"
                hide-details="auto"
                class="mb-6"
                type="number"
              ></v-text-field>
         
              <v-text-field
                v-model="itemData.free_points"
                outlined
                dense
                label="Free Points"
                placeholder="50"
                hide-details="auto"
                class="mb-6"
                type="number"
                :rules="[v => v >= 0 || 'Free Points should be greater 0']"
              ></v-text-field>
       
            <v-file-input
            v-model="itemData.image"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Choose an image"
            outlined
            type="file"
            dense
            label="Upload Image"
            class="mb-1"
            prepend-icon=""
            hint="Image dimensions: min-width: 833px, max-width: 1670px, min-height: 625px, max-height: 1253px, max-file-size: 512kb |dimensions:ratio=4/3"
            :rules="[validators.imageValidator]"
            @click="hideHint()"
          >
          </v-file-input>
          <span v-if="showHint" class="mb-6" style="display:block;font-size: 11px;">Image dimensions: min-width: 833px, max-width: 1670px, min-height: 625px, max-height: 1253px, max-file-size: 512kb |dimensions:ratio=4/3</span>
        </v-col>
          </v-row>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetItemData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { fileRequired, imageValidator, integerValidator, required } from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      showHint: true,
    }
  },
  model: {
    prop: 'isAddNewPackageSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  setup(props, { emit }) {
    const blankItemData = {
      name_en: '',
      name_ar: '',
      price: '',
      points: '',
      free_points: '',

      image: [],
    }

    const categoryChosen = ref('')
    const categoryChosenMeal = ref(false)
    const categoryChosenPreorder = ref(false)

    const subCategories = ref([])
    const chefs = ref([])
    const addOns = ref([])
    const sizes = ref([])
    const priceInPoints = ref(0)
    const sizePriceInPoints = ref(0)
    const isAddOns = ref(false)

    const categoriesOptions = [
      { title: 'Meal', value: '1' },
      { title: 'Pre Order', value: '2' },
    ]

    const isError = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))

      categoryChosen.value = false
      categoryChosenPreorder.value = false
      categoryChosenMeal.value = false
      isAddOns.value = false
      itemData.value.size = []
      resetForm()
      emit('update:is-add-new-item-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-packages/addPackage', itemData.value)
          .then(response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            emit('update:is-add-new-item-sidebar-active', false)
          })
          .catch(error => {
            Object.entries(error.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
                footer:
                  'Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 512kb',
              })
            })
          })
          .finally(() => {
            categoryChosen.value = false
            categoryChosenMeal.value = false
            categoryChosenPreorder.value = false
            isAddOns.value = false
          })

        resetItemData()
      } else {
        validate()
      }
    }

    const setCategory = e => {
      categoryChosen.value = e
      if (e === '1') {
        categoryChosenMeal.value = true
        categoryChosenPreorder.value = false
      } else if (e === '2') {
        categoryChosenPreorder.value = true
        categoryChosenMeal.value = false
      } else {
        categoryChosenPreorder.value = false
        categoryChosenMeal.value = false
      }
      e &&
        store
          .dispatch('app-packages/fetchSubCategories', e)
          .then(response => {
            subCategories.value = response.filteredData
          })
          .catch(error => {
            console.error(error)
          })

      e &&
        store
          .dispatch('app-packages/fetchChefs')
          .then(response => {
            chefs.value = response.data.data.filteredData
          })
          .catch(error => console.error(error))
    }

    const subCatChange = e => {
      if (e !== null) {
        const selectedCat = subCategories.value.filter(item => item.id === e)

        if ((selectedCat[0] && selectedCat[0].add_ones === 1) || selectedCat[0].used_in_all_meals === 1) {
          isAddOns.value = true
        } else {
          isAddOns.value = false

          // Fetch Addons list
          store
            .dispatch('app-packages/fetchAddons')
            .then(response => {
              addOns.value = response['add-ons']
            })
            .catch(error => console.error(error))

          // Fetch Sizes List
          store
            .dispatch('app-packages/fetchSizes')
            .then(response => (sizes.value = response))
            .catch(error => console.error(error))
        }
      }
    }

    const calculatePoints = e => {
      if (e <= 0) return

      store
        .dispatch('app-packages/getPriceInPoints', e)
        .then(response => {
          priceInPoints.value = response.data.data.price
          itemData.value.points = response.data.data.price
        })
        .catch(error => console.error(error))
    }

    const setGetFromOthers = (e, i) => {
      e === true ? (itemData.value.size[i].get_from_others = 1) : (itemData.value.size[i].get_from_others = 0)
    }

    const addSize = () => {
      const obj = {
        name_en: '',
        name_ar: '',
        price: '',
        size_id: '',
        quantity: '',
        image: {},
        get_from_others: 0,
      }

      itemData.value.size.push(obj)
    }

    const addComponent = () => {
      const obj = {
        name_en: '',
        name_ar: '',
      }

      itemData.value.item_components.push(obj)
    }

    const setSizeAr = (e, i) => {
      const [selectedSize] = sizes.value.filter(size => size.name_en === e)
      itemData.value.size[i].name_ar = selectedSize.name_ar
      itemData.value.size[i].size_id = selectedSize.id
    }

    const handleAler = () => {
      isError.value = false
    }

    const removeSize = item => {
      itemData.value.size = itemData.value.size.filter(size => size !== item)
    }

    return {
      resetItemData,
      form,
      onSubmit,
      itemData,
      valid,
      validate,
      isError,
      handleAler,
      categoriesOptions,
      categoryChosenMeal,
      categoryChosenPreorder,
      categoryChosen,
      subCategories,
      chefs,
      priceInPoints,
      sizePriceInPoints,
      isAddOns,
      blankItemData,
      addOns,
      sizes,
      subCatChange,
      setCategory,
      calculatePoints,
      addSize,
      addComponent,
      setSizeAr,
      setGetFromOthers,
      removeSize,

      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
        fileRequired,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
